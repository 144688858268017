<template>
  <ContentWrapper :links="topLinks"> </ContentWrapper>
</template>

<script>
import ContentWrapper from "@/components/ContentWrapper.vue"

export default {
  name: "AnalysisSourcePage",
  components: { ContentWrapper },
  data() {
    return {
      topLinks: [{ title: "发布来源", link: this.$route.path }],
    }
  },
  computed: {},
  watch: {},
  async mounted() {
    await this.reload()
  },
  methods: {
    async reload() {},
  },
}
</script>
